import React from "react";
import { AboutSection } from "../styledComponents/about";

export const AboutPage = () => {
    
    return (
        <>
    <AboutSection />
        </>
    )

}