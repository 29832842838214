import HomeSection from '../styledComponents/home.js';
import React from 'react';

function HomePage() {
    return (
        <>
        <HomeSection />
        </>
    );
}

export default HomePage;