import React from "react";
import "./style/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage  from "./Pages/HomePage.jsx";
import { AboutPage } from "./Pages/AboutPage.jsx";
import Header from "./styledComponents/header.js";
import { ProjectsPage } from "./Pages/ProjectsPage.jsx";
import { PhotosPage } from "./Pages/PhotosPage.jsx";
import { Footer } from "./styledComponents/footer.js";

function App() {
  return (
    <>
      <Header />
      <body>
        <HomePage />
        <AboutPage />
        <ProjectsPage />
        <PhotosPage />
        <Footer />
      </body>
    </>
  );
}

export default App;
