
import React from 'react';
import Projects from '../styledComponents/projects';


export const ProjectsPage = () => {
    return (
        <>
           <Projects />
        </>
    )
}